

import businesswp3 from'./assets/businesswp3.jpg';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero'
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => {
    return (
        <div className="home page-template page-template-template-frontpage page-template-template-frontpage-php page page-id-7">
            <div id="page" class="hfeed site">
                <div id="header-section" className="h-on-top is-transparent">		
                    <div className="followWrap" style={{height: "75px"}}>
                        <Header />
                    </div>  
                    <div id="parallax-hero" className="parallax-hero">  
                        <div className="parallax-bg">
                            <img src={businesswp3} alt="" style={{top: "-160.766px", left: "0px", maxWidth: "1903px"}} />
                        </div>
                        <Hero />
                    </div>
                </div>
                <div id="content" className="site-content">
                    <div id="main" className="site-main" role="main">
                        <About />
                        <Contact />
                    </div>  
                </div>
                <Footer />
            </div>  
        </div>    
    );
}

export default App;
